import { render } from "./LandingPage.vue?vue&type=template&id=4f1cb4d6&scoped=true"
import script from "./LandingPage.vue?vue&type=script&lang=js"
export * from "./LandingPage.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-4f1cb4d6"

export default script
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QChip,QBtn,QImg,QRating,QCard,QCardSection,QTimeline,QTimelineEntry,QCardActions});
