<template>
  <q-toolbar :inset='!is_mobile'>
    <!--
    <q-btn icon="fas fa-cogs" class="q-mr-sm" color="primary" to="/" flat dense> </q-btn>
    -->
    <q-avatar square>
      <img src="/vergicake_logo.png">
    </q-avatar>
    <q-toolbar-title>
      {{company}}
    </q-toolbar-title>

    <q-btn @click="scroll" class="text-bold q-pa-sm" size="lg" flat dense no-caps> Kontakt </q-btn>
  </q-toolbar>
</template>

<script>

export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    is_mobile() {
      return this.$q.platform.is.mobile;
    }
  },
  methods: {
    scroll () {
      const el = document.getElementById('contact')
      const top = el.offsetTop
      window.scrollTo(0, top)
    }
  }
}
</script>

<style lang="scss">
</style>
