<template>
  <!-- Footer -->
  <div class='bg-dark q-pa-md'>

    <div class='column justify-center q-py-md'>
      <div class='text-center'>
        <q-btn class='text-white text-bold' flat color='secondary' dense  rel='nofollow' @click='is_impressum_active = true'> Impressum  </q-btn>
        <div class='text-white copyright'> <strong> Copyright &copy; {{company}} {{year}} </strong> </div>
      </div>
    </div>

  </div>

</template>

<script>
//import Impressum from '@/components/contact/Impressum.vue'

export default {
  name: 'Footer',
  components: {  },
  data() {
    return {
      company: '',
      year: new Date().getFullYear(),
      is_impressum_active: false,
    }
  },
  created() {
    this.company = process.env.VUE_APP_COMPANY_NAME;
    console.log(this.company);
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style lang='scss'>
</style>
