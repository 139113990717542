<template>
  <div class='row q-col-gutter-sm'>
    <div class='col-6' v-for="(item, idx) in cards" :key="'cake' + idx">
      <q-card class='bg-red-1 cursor-pointer text-center' :class='glow_class(idx)' @click='click_card(idx)'>
        <q-card-section class='q-pa-sm'>
          <img :src='"/cake_" + idx2name[idx] + ".png"' style='width: 50%; margin: auto'>
        </q-card-section>
        <q-card-section class='q-pa-sm'>
          <div class="text-h5"> {{item.size }} </div>
          <div class="text-subtitle2">{{item.desc}}</div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Cake',
  components: { },
  props: [ 'cake_size' ],
  data () {
    return {
      highlight: -1,
      idx2name: { 0: 'small', 1: 'big' },
      cards: [
        { size: 'Klein', desc: 'Ab 20cm', price: 'Ab 40€' },
        { size: 'Groß', desc: 'Ab 25cm', price: 'Ab 80€' },
      ]
    }
  },
  methods: {
    click_card(idx) {
      this.highlight = idx;
      this.$emit(`update:cake_size`, idx);
    },
    glow_class(idx) {
      return { glow: this.highlight == idx };
    }
  }
}
</script>

<style scoped lang='scss'>
.glow {
    border: 2px solid #9ecaed;
    border-radius: 7px;
    outline: none;
    box-shadow: 0 0 15px #9ecaed;
}

</style>
