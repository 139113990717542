import { render } from "./Cake.vue?vue&type=template&id=095f006e&scoped=true"
import script from "./Cake.vue?vue&type=script&lang=js"
export * from "./Cake.vue?vue&type=script&lang=js"

import "./Cake.vue?vue&type=style&index=0&id=095f006e&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-095f006e"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection});
