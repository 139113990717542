<template>
  <div class='page'>
    <!-- section splash screen -->
    <div class='section bg-secondary'>
      <div class="text-h4 text-center">
        <strong> Das ideale Geschenk</strong>
      </div>
      <div class="text-h5 q-mb-lg text-center">
        <strong> Tolle Torten für besondere Anlässe 🥰❤️ </strong>
      </div>
      <div class="row q-col-gutter-md">
        <div class="col-12 col-sm-6">

          <!-- Perks -->
          <div class="column items-start q-my-md">
            <q-chip size="1.0rem" text-color="dark" color="grey-2" icon="fas fa-gifts">Perfekt für Geburtstage uvm!</q-chip>
            <q-chip size="1.0rem" text-color="dark" color="grey-2" icon="fas fa-shipping-fast">Schnell und lecker</q-chip>
            <q-chip size="1.0rem" text-color="dark" color="grey-2" icon="fas fa-thumbs-up">Über 500 Kuchen in der Gallery</q-chip>
            <q-chip size="1.0rem" text-color="dark" color="grey-2" icon="fab fa-whatsapp">Einfach über Whatsapp bestellen</q-chip>
          </div>
          <!-- Perks -->

          <!-- call to action -->
          <q-btn class='q-my-lg' color='green-5' icon='fas fa-arrow-alt-circle-down' size='lg'
            icon-right='fab fa-whatsapp' @click='scroll' label='Jetzt anfragen' no-caps/>
          <!-- call to action -->

          <!-- Google Rating
          <q-btn class="text-dark bg-grey-2" @click='open_google' no-caps>
            <div class="flex inline items-center q-my-sm">
              <q-img src="/google.png" style="width:40px"> </q-img>
              <div class="q-mx-md">
                <div class="text-subtitle1"> Google Rating </div>
                <div class="q-gutter-y-md column">
                  <q-rating v-model="rating_google" color="orange-4" size="1.2em" icon="fas fa-star" icon-selected="fas fa-star" icon-half="fas fa-star-half-alt" readonly/>
                </div>
              </div>
              <div class="text-h6"> <strong> {{rating_google}} </strong> </div>
            </div>
          </q-btn>
          -->
        </div>

        <div class="col-12 col-sm-6 flex justify-center">
          <q-card class="bg-amber-3" style="max-width:400px" flat>
            <q-img src="/cake.jpg"/>

            <q-card-section>
              <div class="text-h6">Wir sorgen für die leckersten Kuchen in NRW! </div>
              <div class="text-subtitle2">Das {{company}} Team</div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <!-- section splash screen -->

    <!-- section product -->
    <div class="section text-center">
      <div class="text-h4 q-mb-xl text-center">
        <strong> Unsere Gallery </strong>
      </div>
      <div class="q-mb-xl text-center">
        Mit über 500 <strong> einzigartigen </strong> Kreationen und über <strong> 10 Jahren </strong> Erfahrung
        bieten wir Kuchen & Torten höchster Qualität!
      </div>

      <!-- instagram posts -->
      <div class='row'>
        <div class="col-12 col-sm-4 q-pa-sm" v-for="(item, idx) in posts" :key="'card' + idx">
          <IGEmbed :photo_id="item" style='height: 630px'/>
        </div>
      </div>
      <!-- instagram posts -->

      <q-btn class="bg-pink-5 text-white" label="Mehr auf Instagram"  icon="fab fa-instagram" icon-right="fas fa-share"
        @click="open_instagram" no-caps/>
    </div>
    <!-- section product -->

    <!-- section contact -->
    <div id="contact">
      <!-- section trivia -->
      <div class="section bg-secondary text-center">
        <div class="text-h4 q-mb-lg text-center">
          <strong> Kontakt </strong>
        </div>

        <div class='row justify-center'>
          <div class="col" style='max-width:500px;'>
            <q-timeline class="q-mt-lg" color="primary">
              <q-timeline-entry heading tag="h4"> So einfach geht's </q-timeline-entry>

              <q-timeline-entry title="Größe auswählen" icon="fab fa-whatsapp">
                <Cake v-model:cake_size='cake_size'/>
              </q-timeline-entry>

              <q-timeline-entry title="Nachricht Nachricht schreiben" icon="fab fa-whatsapp">
                <div>
                  Teile uns deine Kuchenvorstellung mit. Schick uns dazu am besten Beispielbilder.
                </div>
              </q-timeline-entry>

              <q-timeline-entry title="Kuchen abholen" icon="fas fa-euro-sign" >
                <div>
                  Im Moment sind nur Abholungen in Gütersloh möglich - Bitte beachte das bei deiner Bestellung.
                </div>
              </q-timeline-entry>
            </q-timeline>


            <div class="q-gutter-md q-ma-sm">
              <!-- whatsapp -->
              <div style="min-width:300px">
                <q-card class='bg-green-2'>
                  <q-card-section>
                    <div class="text-h6"> <i class="fab fa-whatsapp"></i> Kontakt</div>
                    <div class="text-subtitle2">Hier klicken zum Chat</div>
                  </q-card-section>
                  <q-card-actions vertical>
                    <!--
                    <q-btn class="bg-green-5 text-white" label="Whatsapp (Hier klicken)"  icon="fab fa-whatsapp" @click="open_whatsapp" no-caps/>
                    -->
                    <q-btn class="bg-pink-5 text-white" label="Instagram (Hier klicken)"  icon="fab fa-instagram" @click="open_instagram" no-caps/>
                  </q-card-actions>
                </q-card>
              </div>
              <!-- whatsapp -->

              <!-- instagram -->
              <div style="min-width:300px">
                <q-card class='bg-pink-2'>
                  <q-card-section>
                    <div class="text-h6"> <i class="fab fa-instagram"></i> Instagram</div>
                    <div class="text-subtitle2">Mehr Kuchenbilder & Ideen findest du hier</div>
                  </q-card-section>
                  <q-card-actions vertical>
                    <q-btn class="bg-pink-5 text-white" label="Instagram (Hier klicken)"  icon="fab fa-instagram" @click="open_instagram" no-caps/>
                  </q-card-actions>
                </q-card>
              </div>
              <!-- instagram -->

              <!-- logo -->
              <img class='q-pt-md' src="/vergicake_logo2.png" style="max-width:300px"/>
              <!-- logo -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- section contact -->

    <!-- section standorte
    <div class="section">
      <div class="text-h4 q-mb-xl text-center">
        <strong> Unser Standort </strong>
      </div>
      <div class="column q-col-gutter-md">
        <div style="height:500px; width:100%">
          <bing-map credentials="An1BQG07ffSwVfXzEXy-Ai_UUkU7VUpo_jSht8G5j2NyQZGTNLrtFE6gnUoYAWCR" :options="mapOptions">
            <bing-map-layer name="layer-1" :visible="true">
              <bing-map-pushpin v-for="item in pins" :key="item.key" :location="item.location" :options="item.options">
              </bing-map-pushpin>
            </bing-map-layer>
          </bing-map>
        </div>
      </div>
    </div>
    section standorte -->

  </div>
</template>

<script>

import { copyToClipboard, openURL } from 'quasar'
import Cake from '@/components/Cake.vue'
import IGEmbed from '@/components/IGEmbed.vue'

export default {
  name: 'LandingPage',
  components: { Cake, IGEmbed },
  data () {
    return {
      tel: '123',
      rating_google: 4.9,
      email: 'info@vergicake.de',
      cake_size: 0,
      posts: [
        "CPU_byRpLHR",
        "CQtTlTIJzEq",
        "CT9LH-xMmN6",
        "CQ8VVn-lnEv",
        //"CQk7Tselyhx",
        //"CN4OXwnJ4RR",
        "CJxvPg2JvKK",
        //"CMfFId3plbb",
        "CHkbWEop-Az",
        "CHcdEgGJ6JA",
        "CGZ3iosJLSA",
        "CFUbPQcJwdG",
        "CC5Z74MJE4O",
        "CUC1_9fMVKi",
        "CEd4GFFJmQS",
      ],
    }
  },
  methods: {
    copy_to_clipboard () {
      copyToClipboard(this.email).then(() => {
        console.log('ok')
      })
    },
    scroll () {
      const el = document.getElementById('contact')
      const top = el.offsetTop - 50;
      window.scrollTo(0, top)
    },
    generate_ticket() {
      // generate ticket
      const t = new Date();
      const y = new Intl.DateTimeFormat(undefined, { year: '2-digit' }).format(t);
      const m = new Intl.DateTimeFormat(undefined, { month: '2-digit' }).format(t);
      const d = new Intl.DateTimeFormat(undefined, { day: '2-digit' }).format(t);
      const ticket = y + m + d + "A";
      return ticket;
    },
    open_whatsapp () {
      // trigger fb event
      /* eslint-disable no-undef */
      fbq('track', 'Contact', {});
      // generate ticket id
      const ticket = this.generate_ticket();
      let tel = this.tel;
      const idx2name = { 0: 'kleinen', 1: 'großen' };
      const cake_size = idx2name[this.cake_size];
      // open wahtsapp
      let url = `https://wa.me/${tel}`;
      url += `?text=[Ticket: ${ticket}]%0AHi VergiCake 🍰, Ich möchte einen ${cake_size} Kuchen kaufen.`
      url +=  ` Melde dich bei mir für mehr Informationen 🙂%0AViele Grüße`;
      openURL(url);
    },
    open_instagram () {
      const url = 'https://www.instagram.com/vergicake/';
      openURL(url);
    },
    open_google () {
      //openURL(this.google_url)
    },
    click_call () {
      window.open('tel:+4915228864786')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
