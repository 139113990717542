import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '@/pages/landing_page/LandingPage.vue'
import MainLayout from '@/layouts/MainLayout.vue'

const routes = [
    {
      path: '/',
      component: MainLayout,
      children: [ {
        path: '', component: LandingPage,
      } ]
    },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
