import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

const app = createApp(App);

// global vars
app.config.globalProperties.company = process.env.VUE_APP_COMPANY_NAME;

// injection
app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);

app.mount('#app')
