<template>
  <div v-html='html' />
</template>

<script>
/* eslint-disable no-console */
export default {
  name: 'vue-instagram-embed',
  props: {
    photo_id: { type: String },
  },
  data() {
    return {
      html: '<div/>',
    };
  },
  mounted() {
    const photo_id = this.photo_id;
    if (photo_id == '')
      return;
    this.check_api().then(() => {
      this.html = `<blockquote class="instagram-media" data-instgrm-version="13" style="max-width:326px"> <a href="https://instagram.com/p/${photo_id}/" /> </blockquote>`;
      this.$nextTick(() => {
        window.instgrm.Embeds.process();
      });
    });
  },
  methods: {
    check_api() {
      return new Promise(resolve => {
        (function check_api_fn(self) {
          self.timer = window.setTimeout(() => {
            if (window.instgrm) {
              clearTimeout(self.timer);
              resolve();
            } else {
              check_api_fn(self);
            }
          }, 50);
        })(this);
      });
    }
  }
}
</script>
