<template>
  <q-layout view="hHh lpr lFf">
    <q-header class="bg-dark">
      <Header/>
    </q-header>

    <q-page-container class="page">
      <router-view />
    </q-page-container>

    <Footer/>
  </q-layout>
</template>

<script>

import Header from '@/layouts/Header.vue'
import Footer from '@/layouts/Footer.vue'

export default {
  name: 'MainLayout',
  components: { Header, Footer },
  data () {
    return {
    }
  }
}
</script>
