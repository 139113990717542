import { render } from "./MainLayout.vue?vue&type=template&id=1f042c4a"
import script from "./MainLayout.vue?vue&type=script&lang=js"
export * from "./MainLayout.vue?vue&type=script&lang=js"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QPageContainer});
